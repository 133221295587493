//https://devinterno.atlassian.net/jira/software/projects/PR/boards/10?selectedIssue=PR-1717

export default [
    {
        id: '1',
        nss: '4376010133',
        nombre: "CAROLINA CARRILLO VAZQUEZ",
        salario: "350.00",
        ext: "0",
        umf: "0",
        tsalario: "355.00",
        fecha_movimiento: "01/01/2023",
        t_trab: "1",
        t_jornada: "1",
        t_trabajador: "Asalariado",
        curp: "AACJ890412HYNRRS04",
        c_baja: "2"
    },
    {
        id: '2',
        nss: '4376010133',
        nombre: "ISAURA PATRICIA MORALES CASTELLANOS",
        salario: "350.00",
        ext: "0",
        umf: "0",
        tsalario: "355.00",
        fecha_movimiento: "01/01/2023",
        t_trab: "1",
        t_jornada: "1",
        t_trabajador: "Asalariado",
        curp: "AACJ890412HYNRRS04",
        c_baja: "2"
    },
    {
        id: '3',
        nss: '4376010133',
        nombre: "MITSHELL BERENICE ESTEVA CERVERA",
        salario: "350.00",
        ext: "0",
        umf: "0",
        tsalario: "355.00",
        fecha_movimiento: "01/01/2023",
        t_trab: "1",
        t_jornada: "1",
        t_trabajador: "Asalariado",
        curp: "AACJ890412HYNRRS04",
        c_baja: "2"
    },
    {
        id: '4',
        nss: '4376010133',
        nombre: "DANIEL PEREZ AYUSO",
        salario: "350.00",
        ext: "0",
        umf: "0",
        tsalario: "355.00",
        fecha_movimiento: "01/01/2023",
        t_trab: "1",
        t_jornada: "1",
        t_trabajador: "Asalariado",
        curp: "AACJ890412HYNRRS04",
        c_baja: "2"
    }
]