<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row class="pa-0 mt-0 mb-0">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 ">
                    <div style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                        <hr class="vr"/>
                        <p class="tblName pb-2">Seguridad social</p>
                    </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 d-flex justify-end">
                    
                    <v-btn
                        elevation="0"
                        class="btnNormal mr-3"
                        @click="() => {}"
                    >
                        Restaurar tablero
                    </v-btn>
                    <v-btn
                        height="40"
                        width="40"
                        fab
                        color="#FFFFFF"
                        class="elevation-0 mt-0"
                        @click="abrirModalBuscar()"
                    >
                        <v-icon color="#96999A">mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <div class="list-grid mt-5">
                <div class="card-grid">
                    <div class="pa-6">
                        <div class="text-card-01">Registro patronales</div>
                        <div class="text-card-02">0</div>
                        <div class="text-card-03">Registrados en la cuenta</div>
                    </div>
                </div>
                <div class="card-grid">
                    <div class="pa-6">
                        <div class="text-card-01">Sucursales registradas</div>
                        <div class="text-card-02">0</div>
                        <div class="text-card-03"></div>
                    </div>
                </div>
                <div class="card-grid">
                    <div class="pa-6">
                        <div class="text-card-01">Certificados por vencer</div>
                        <div class="text-card-02">0</div>
                        <div class="text-card-03">10 días o menos vencen</div>
                    </div>
                </div>
                <div class="card-grid">
                    <div class="pa-6">
                        <div class="text-card-01">Lotes sin respuesta</div>
                        <div class="text-card-02">0</div>
                        <div class="text-card-03">0% Lotes sin procesar</div>
                    </div>
                </div>
                <div class="card-grid">
                    <div class="pa-6">
                        <div class="text-card-01">Trabajadores activos</div>
                        <div class="text-card-02">0</div>
                        <div class="text-card-03">Registrados en la cuenta</div>
                    </div>
                </div>
            </div>


            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="mt-5">
                    <div class="card-grafica mt-5">
                        <div class="pa-5">
                            <div class="titulo-grafica">Movimientos del año</div>
                            <v-chart
                                style="width:100%; height:500px; position:relative;"
                                :options="grafica01"
                                :autoresize="true"
                            />
                        </div>  
                    </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"  class="mt-5">
                    <div class="card-grafica mt-5">
                        <div class="pa-5">
                            <div class="titulo-grafica">Acumulados</div>
                            <v-chart
                                style="width:100%; height:300px; position:relative;"
                                :options="grafica02"
                                :autoresize="true"
                            />
                            
                        </div>  
                    </div>
                </v-col>
            </v-row>


            <v-row class="pa-0 ma-0">
                <v-col cols="12" lg="12" class="mt-5">
                    <data-table
                        ref="tablaMovimientosProgramados"
                        :tableName="'Movimientos Programados'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.fecha_envio}}</div></td>
                                <td><div>{{item.lote}}</div></td>
                                <td><div>{{item.reingresos}}</div></td>
                                <td><div>{{item.bajas}}</div></td>
                                <td><div>{{item.modificaciones}}</div></td>
                                <td><div>{{item.estatus}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>


            <v-row class="pa-0 ma-0">
                <v-col cols="12" lg="12" class="mt-5">
                    <data-table
                        ref="tablaFirmasExpiradas"
                        :tableName="'Firmas expiradas'"
                        :url="urlFirmas"
                        :columns="columns_firmas"
                        :filters="filters_firmas"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.registro_patronal}}</div></td>
                                <td><div>{{item.razon_social}}</div></td>
                                <td><div>{{item.fecha_creacion}}</div></td>
                                <td><div>{{item.fecha_vencimiento}}</div></td>
                                <td><div>{{item.tipo_cetificado}}</div></td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <div class="mb-15"></div>



































            

        </v-container>
    </div>
</template>


<script>
import BtnAcciones from '@/components/BtnAcciones.vue';
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';
import lotes from '../../js/lotes.js';

export default {
    components: {
        'data-table': Datatable,
        BtnAcciones,
    },
    data() {
        return {
            url         : "imss-moviento-programados",
            urlFirmas   : "imss-moviento-programados",
            columns     : [
                {
                    label: 'Fecha de envío',
                    name: 'fecha_envio',
                    filterable: false
                },
                {
                    label: 'Lote',
                    name: 'lote',
                    filterable: false
                },
                {
                    label: 'Reingresos',
                    name: 'reingresos',
                    filterable: false
                },
                {
                    label: 'Bajas',
                    name: 'bajas',
                    filterable: false
                },
                {
                    label: 'Modificaciones',
                    name: 'modificaciones',
                    filterable: false
                },
                {
                    label: 'Estatus',
                    name: 'estatus',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    filterable: false
                },
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            columns_firmas: [
                {
                    label: 'Registro Patronal',
                    name: 'registro_patronal',
                    filterable: false
                },
                {
                    label: 'Razón social',
                    name: 'razon_social',
                    filterable: false
                },
                {
                    label: 'Fecha de creación',
                    name: 'fecha_creacion',
                    filterable: false
                },
                {
                    label: 'Fecha de vencimiento',
                    name: 'fecha_vencimiento',
                    filterable: false
                },
                {
                    label: 'Tipo de certificado',
                    name: 'tipo_cetificado',
                    filterable: false
                },
            ],
            filters_firmas: {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            imagen          : "",
            dialog          : false,
            isLoading       : false,
            isSaving        : false,

            grafica01: {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    padding : 0,
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name:'Reingresos',
                        type:'line',
                        data: [120, 132, 101, 134, 90, 230, 210, 345, 874, 860, 24, 42],
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: {
                                    type: 'linear',
                
                                    colorStops: [{
                                        offset: 0,
                                        color: '#219653'
                                    }, {
                                        offset: 1,
                                        color: '#219653'
                                    }],
                                },
                            }
                        },
                        itemStyle: {
                                normal: {
                                color: '#219653',
                                borderWidth: 10,
                                borderColor: "#219653"
                            }
                        },
                    },

                    {
                        name:'Modificaciones',
                        type:'line',
                        data: [220, 182, 191, 234, 290, 330, 310, 34, 345, 67, 94, 300],
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: {
                                    type: 'linear',
                
                                    colorStops: [{
                                        offset: 0,
                                        color: '#F2C94C'
                                    }, {
                                        offset: 1,
                                        color: '#F2C94C'
                                    }],
                                },
                            }
                        },
                        itemStyle: {
                                normal: {
                                color: '#F2C94C',
                                borderWidth: 10,
                                borderColor: "#F2C94C"
                            }
                        },
                    },
                    
                    {
                        name:'Bajas',
                        type:'line',
                        data: [150, 132, 291, 154, 590, 530, 210, 23, 473, 92, 348, 399],
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: {
                                    type: 'linear',
                
                                    colorStops: [{
                                        offset: 0,
                                        color: '#EB5757'
                                    }, {
                                        offset: 1,
                                        color: '#EB5757'
                                    }],
                                },
                            }
                        },
                        itemStyle: {
                                normal: {
                                color: '#EB5757',
                                borderWidth: 10,
                                borderColor: "#EB5757"
                            }
                        },
                    },

                ]
            }, // Fin grafica 01


            grafica02: {
                grid: {
                    left: "5%",
                    right: "5%",
                    bottom: "5%",
                    top: "5%",
                    containLabel: false,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                    formatter: function(params) {
                        return (
                            params[0].name +
                            "<br/>" +
                            "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
                            params[0].value +
                            "% <br/>"
                        );
                    },
                    /* formatter: function(params) {
                                return params[0].name + '<br/>' +
                                    "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
                                    params[0].seriesName + ' : ' + Number((params[0].value.toFixed(4) / 10000).toFixed(2)).toLocaleString() + '<br/>'
                        } */
                },
                backgroundColor: "#FFFFFF",
                xAxis: {
                    show: false,
                    type: "value",
                },
                yAxis: [
                    {
                        type    : "category",
                        inverse : true,
                        axisLabel: {
                            show: false, //poner el label del lado izquierdo sobre el eje y
                            textStyle: {
                                color: "#96999A",
                                fontFamily: "Montserrat",
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        data: [],
                    },
                    {
                        type    : "category",
                        offset  : -80,
                        inverse : true,
                        axisTick: "none",
                        axisLine: "none",
                        show    : true,
                        axisLabel: {
                            show    : true,
                            inside  : false,
                            verticalAlign: "right",
                            lineHeight: "-40",
                            textStyle   : {
                                color       : "#96999A",
                                fontSize    : "12",
                                fontFamily  : "Montserrat",
                            },
                            formatter: function(value) {
                                let str = "";
                                if (value == 100) {
                                    return "¡Completo!";
                                } else {
                                    return "            " + value.toLocaleString() + "%";
                                }
                            },
                        },
                        data: [],
                    },
                ],
                series: [
                    {
                        name: "No. Documentos",
                        showBackground: true,
                        backgroundStyle: {
                            color: "#F2F2F2",
                            barBorderRadius: 5,
                        },
                        type: "bar",
                        zlevel: 1,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 5,
                                color: function(params) {
                                    if (params.data.value == 100) {
                                        return "#50E18E";
                                    } 
                                    else if(params.data.value > 50 && params.data.value < 100){
                                        return "#FFC85F";
                                    }
                                    else {
                                        return "#E75858";
                                    }
                                },
                            },
                        },
                        barWidth: 20,
                        data: [],
                        label: {
                            normal: {
                                color: "#b3ccf8",
                                show: true,
                                position: [0, "-20px"],
                                textStyle: {
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                },
                                formatter: function(a) {
                                    let num = "";
                                    let str = "";
                                    str = `{colorTitulo| ${a.name}}`;
                                    return str;
                                },
                                rich: {
                                    colorTitulo: {
                                        color: "#57575C",
                                        fontWeight: 600,
                                        fontFamily: "Montserrat",
                                    },
                                },
                            },
                        },
                    },
                    {
                        name: "nombre2",
                        type: "bar",
                        barWidth: 20,
                        barGap: "-100%",
                        data: [],
                        itemStyle: {
                            normal: {
                                color: "#F2F2F2",
                                barBorderRadius: 5,
                            },
                        },
                    },
                ],
            }, //termina grafica02

        }
    },
    methods: {

        llenarGrafica02() {
            this.grafica02.yAxis[0].data = 
            [
                {
                    "value": "Altas procesadas",
                    "documento": {
                        "id": 1,
                        "nombre": "Altas procesadas",
                        "total": 2
                    }
                },
                {
                    "value": "Bajas procesadas",
                    "documento": {
                        "id": 3,
                        "nombre": "Bajas procesadas",
                        "total": 3
                    }
                },
                {
                    "value": "Modificaciones procesadas",
                    "documento": {
                        "id": 2,
                        "nombre": "Modificaciones procesadas",
                        "total": 60
                    }
                }
            ]; 



            this.grafica02.yAxis[1].data =
            [
                {
                    "value": 2,
                    "documento": {
                        "id": 1,
                        "nombre": "Altas procesadas",
                        "total": 2
                    }
                },
                {
                    "value": 3,
                    "documento": {
                        "id": 3,
                        "nombre": "Bajas procesadas",
                        "total": 3
                    }
                },
                {
                    "value": 60,
                    "documento": {
                        "id": 2,
                        "nombre": "Modificaciones procesadas",
                        "total": 60
                    }
                }
            ]



            this.grafica02.series[0].data =
            [
                {
                    "value": 2,
                    "documento": {
                        "id": 1,
                        "nombre": "Altas procesadas",
                        "total": 2
                    }
                },
                {
                    "value": 3,
                    "documento": {
                        "id": 3,
                        "nombre": "Bajas procesadas",
                        "total": 3
                    }
                },
                {
                    "value": 60,
                    "documento": {
                        "id": 2,
                        "nombre": "Modificaciones procesadas",
                        "total": 60
                    }
                }
            ]




            this.grafica02.series[1].data = 
            [
                {
                    "value": 100,
                    "documento": {
                        "id": 1,
                        "nombre": "Altas procesadas",
                        "total": 2
                    }
                },
                {
                    "value": 100,
                    "documento": {
                        "id": 3,
                        "nombre": "Bajas procesadas",
                        "total": 3
                    }
                },
                {
                    "value": 100,
                    "documento": {
                        "id": 2,
                        "nombre": "Modificaciones procesadas",
                        "total": 60
                    }
                }
            ]
        }

    },

    created() {
        this.llenarGrafica02();
    },
    
    mounted(){

    },
    updated(){
    }
}
</script>

<style scoped>
    .list-grid {
        display: grid;
        height: 125px;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
    }

    .list-grid > div {
        background-color: #FCFCFC;
        border-radius: 12px;
    }

    .text-card-01 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #4F4F4F;
    }

    .text-card-02 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-align: left;
        color: #4F4F4F;
    }

    .text-card-03 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-align: left;
        color: #828282;
    }

    .card-grafica {
        background-color: #FFFFFF;
        border-radius: 12px;
    }

    .titulo-grafica {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: left;
        color: #444975;
    }
    
</style>